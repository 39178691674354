<template>
  <div
    id="contents"
    :class="{
      status_board_cns: tabIndex < 4,
      plc_monitor: tabIndex == 0 || tabIndex == 1 || tabIndex == 3,
      plc_th_status: tabIndex == 0,
      plc_metal_status: tabIndex == 1,
      x_ray_status: tabIndex == 2,
      lincoln_oven_status: tabIndex == 3,
      default_setting: tabIndex == 4,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToPlcMonitorPage'"
          :options="[
            { title: '실시간 온습도 현황', name: 'real_time_status' },
            { title: '실시간 금속검출 현황', name: 'counter_time_status' },
            { title: '실시간 X-ray 현황', name: 'xray_status' },
            { title: '링컨오븐기 현황', name: 'lincoln_oven_status' },
            { title: '기준값 설정', name: 'default_setting' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="{ active: tabIndex == 0 }">
            <a @click="SetOpenTabIndex(0)">실시간 온습도 현황</a>
          </li>
          <li :class="{ active: tabIndex == 1 }">
            <a @click="SetOpenTabIndex(1)">실시간 금속검출 현황</a>
          </li>
          <li :class="{ active: tabIndex == 2 }">
            <a @click="SetOpenTabIndex(2)">실시간 X-ray 현황</a>
          </li>
          <li :class="{ active: tabIndex == 3 }">
            <a @click="SetOpenTabIndex(3)">링컨오븐기 현황</a>
          </li>
          <li :class="{ active: tabIndex == 4 }">
            <a @click="SetOpenTabIndex(4)">기준값 설정</a>
          </li>
        </ul>
      </div>
      <temp-realtime-form v-if="tabIndex == 0"></temp-realtime-form>
      <counter-realtime-form v-if="tabIndex == 1"></counter-realtime-form>
      <xray-realtime-form v-if="tabIndex == 2"></xray-realtime-form>
      <lincoln-oven-form v-if="tabIndex == 3"></lincoln-oven-form>
      <default-plc-form v-if="tabIndex == 4"></default-plc-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import TempRealtimeForm from '@/views/forms/Custom/Monitor/17/TempRealtimeForm';
import CounterRealtimeForm from '@/views/forms/Custom/Monitor/17/CounterRealtimeForm';
import XrayRealtimeForm from '@/views/forms/Custom/Monitor/17/XrayRealtimeForm';
import LincolnOvenForm from '@/views/forms/Custom/Monitor/17/LincolnOvenForm';
import DefaultPlcForm from '@/views/forms/Custom/Monitor/17/DefaultPlcForm';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    AsideSelectric,
    TempRealtimeForm,
    CounterRealtimeForm,
    XrayRealtimeForm,
    LincolnOvenForm,
    DefaultPlcForm,
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToPlcMonitorPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitPlcMonitorPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss">
@import 'cns_monitoring';
</style>
