<template>
  <div class="article" :class="{ active: fullScreen == true }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <button class="btn_sub1" @click="fullScreen = !fullScreen">
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <div class="monitor_wrap">
        <div class="monitor_head">
          <h2>실시간 X-RAY검출 현황</h2>

          <span v-if="cns_xray_log_now.length > 0"
            >조회시간 :
            {{ cns_xray_log_now[0].create_time | formatDateHHMMSS }}</span
          ><span v-else>조회시간 : NaN</span>
        </div>
        <div class="monitor_body">
          <div class="monitor_card machine_status">
            <h3>장비상태</h3>
            <p>
              {{
                cns_xray_log_now.length > 0
                  ? getXrayStatus(cns_xray_log_now[0].status)
                  : 'loading'
              }}
            </p>
          </div>
          <div class="monitor_card">
            <h3>진행 수</h3>
            <p>
              {{
                cns_xray_log_now.length > 0
                  ? $makeComma($makeNumber(cns_xray_log_now[0].total_count))
                  : 'loading'
              }}
            </p>
          </div>
          <div class="monitor_card">
            <h3>현재 예약번호</h3>
            <p>
              {{
                cns_xray_log_now.length > 0
                  ? $makeComma(cns_xray_log_now[0].reservation_num)
                  : ''
              }}
            </p>
          </div>
          <div class="monitor_card">
            <h3>에러코드</h3>
            <p>
              {{
                cns_xray_log_now.length > 0
                  ? cns_xray_log_now[0].error_code
                  : ''
              }}
            </p>
          </div>
        </div>
        <div class="monitor_body">
          <div class="monitor_card good_card">
            <h3>양품</h3>
            <p>
              {{
                cns_xray_log_now.length > 0
                  ? $makeComma($makeNumber(cns_xray_log_now[0].pass_count))
                  : 'loading'
              }}
            </p>
          </div>
          <div class="monitor_card bad_card">
            <h3>불량</h3>
            <p>
              {{
                cns_xray_log_now.length > 0
                  ? $makeComma($makeNumber(cns_xray_log_now[0].error_count))
                  : 'loading'
              }}
            </p>
          </div>
          <div class="monitor_card bad_card">
            <h3>불량률</h3>
            <p>
              {{
                cns_xray_log_now.length > 0
                  ? $makeComma(
                      (
                        ($makeNumber(
                          cns_xray_log_now[0].error_count
                            ? cns_xray_log_now[0].error_count
                            : 0,
                        ) /
                          ($makeNumber(
                            cns_xray_log_now[0].error_count
                              ? cns_xray_log_now[0].error_count
                              : 0,
                          ) +
                            $makeNumber(
                              cns_xray_log_now[0].pass_count
                                ? cns_xray_log_now[0].pass_count
                                : 1,
                            ))) *
                        100
                      ).toFixed(2),
                    )
                  : '0'
              }}%
            </p>
          </div>
        </div>
        <div class="monitor_body">
          <div class="monitor_card bad_card">
            <h3>이물</h3>
            <p>
              {{
                cns_xray_log_now.length > 0
                  ? cns_xray_log_now[0].error_foreign_body
                  : ''
              }}
            </p>
          </div>
          <div class="monitor_card bad_card">
            <h3>결품</h3>
            <p>
              {{
                cns_xray_log_now.length > 0
                  ? cns_xray_log_now[0].error_shortage
                  : ''
              }}
            </p>
          </div>
          <div class="monitor_card bad_card">
            <h3>물품 길이 오류</h3>
            <p>
              {{
                cns_xray_log_now.length > 0
                  ? cns_xray_log_now[0].error_length
                  : ''
              }}
            </p>
          </div>
          <div class="monitor_card bad_card">
            <h3>검사 범위 외</h3>
            <p>
              {{
                cns_xray_log_now.length > 0
                  ? cns_xray_log_now[0].error_other
                  : ''
              }}
            </p>
          </div>
          <div class="monitor_card bad_card">
            <h3>금속 혼입</h3>
            <p>
              {{
                cns_xray_log_now.length > 0
                  ? cns_xray_log_now[0].error_metal_mixing
                  : ''
              }}
            </p>
          </div>
          <div class="monitor_card bad_card">
            <h3>외부 불량</h3>
            <p>
              {{
                cns_xray_log_now.length > 0
                  ? cns_xray_log_now[0].error_external_defect
                  : ''
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';

export default {
  mixins: [FETCH_MIXIN],
  data() {
    return {
      counter_interval: null,
      timer: 1,
      fullScreen: false,
    };
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
      products: 'getProduct',
      cns_xray_log_now: 'getCNSXrayLog',
    }),
  },
  methods: {
    getXrayStatus(status) {
      switch (status) {
        case 0:
          return '정지 중';
        case 1:
          return '장치 이상으로 정지 중';
        case 2:
          return 'X선 준비중';
        case 3:
          return '운전대기';
        case 4:
          return '검사 중';
        default:
          return '';
      }
    },
  },
  watch: {
    async timer(newValue) {
      if (newValue == 0 && this.tabIndex == 1) {
        await this.FETCH_NO_SPINNER('FETCH_CNS_XRAY_LOG', 'X-RAY 검출값');
        this.timer = 10;
      }
    },
  },
  async created() {
    await this.FETCH('FETCH_CNS_XRAY_LOG', 'X-RAY 검출값');
  },
};
</script>

<style lang="scss" scoped></style>
